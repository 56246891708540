import { DataStore } from "aws-amplify"
import { Coach, CoachedParticipant } from "../models"
import _ from 'lodash';
import React, { useState } from 'react';
import {Button, Heading, TextField } from '@aws-amplify/ui-react';
import { PermissionedUserProps } from "./sharedInterface";
import { Groups } from "../common/types";


type CreateCoachedParticipantRequest = {
    participantId?: string
    coachId?: string
    participantNickname?: string
    zoneInfo?: string


}

const stringIsEmpty = (string?: string) => {
    return _.isNil(string) || !Boolean(string.length)
}

const isCoachedParticipantRequestComplete = (createCoachedParticipantRequest?: CreateCoachedParticipantRequest): boolean => {
    return !stringIsEmpty(createCoachedParticipantRequest?.participantId) && !stringIsEmpty(createCoachedParticipantRequest?.coachId)
}

export const createCoachedParticipant = async (createCoachedParticipantRequest?: CreateCoachedParticipantRequest): Promise<boolean> => {
    console.log({createCoachedParticipantRequest})
    if (isCoachedParticipantRequestComplete(createCoachedParticipantRequest)
        && createCoachedParticipantRequest && createCoachedParticipantRequest.coachId && createCoachedParticipantRequest.participantId) {
        try {
          console.log("will save")

          await DataStore.save(
            new CoachedParticipant({ coach: createCoachedParticipantRequest.coachId, participant: createCoachedParticipantRequest.participantId, participantNickname: createCoachedParticipantRequest.participantNickname ?? "" })
          )
          console.log("did save")

          return Promise.resolve(true)
        } catch (error) {
          console.log("Could not save CoachedParticipant")      
        }
    } else {
      console.log("Not complete")
    }
    return Promise.resolve(false)
}



export const createCoach = async (coachId?: string, nickname?: string): Promise<boolean> => {

  if (coachId && nickname) {
    try {
      await DataStore.save(new Coach({coachId: coachId, nickname: nickname}))
      return Promise.resolve(true)
    } catch (error) {
      console.log("Could not save Coach")      
    }
  }

  return Promise.resolve(false)

}


export const AddCoach = ({groups}: PermissionedUserProps): JSX.Element => {
  const [coachId, setCoachId] = useState<string|undefined>(undefined)
  const [nickname, setNickname] = useState<string|undefined>(undefined)

  
  return groups.includes(Groups.studyCoordinators) ? (
      <>
      <Heading level={3}>Add Coach</Heading>
      <TextField
        label="Coach ID"
        width="300px"
        value={coachId}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setCoachId(e.currentTarget.value)
        }}></TextField>
      <TextField
        label="Coach Nickname"
        width="300px"
        value={nickname}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setNickname(e.currentTarget.value)
        }}></TextField>


      <Button onClick={(e) => {

        createCoach(coachId, nickname)
          .then((didSucceed) => {
            if (didSucceed) {
              setCoachId("")
              setNickname("")
            }
          }) 

      }}>Add</Button>

    </>        
  ) : <></>
}



