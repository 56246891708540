import {Amplify, Auth, API } from 'aws-amplify';

import awsconfig from '../aws-exports';
import dayjs from 'dayjs';
Amplify.configure(awsconfig);

export class Emailer {

    /**
     * 
     * @returns true if the emails were sent
     */
    public async sendAppointmentEmails(participantEmail: string, participantNickname: string, participantZoneinfo: string, coachEmail: string, coachNickname: string, coachZoneinfo: string, appointmentTime: dayjs.Dayjs, appointmentURL: string): Promise<boolean> {
        const apiName = 'EmailSender';
        const path = '/scheduledAppointments';
        let myInit = {
            body: { participantEmail, participantNickname, participantZoneinfo, coachEmail, coachNickname, coachZoneinfo, appointmentTime: appointmentTime.toISOString(), appointmentURL }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        const session = (await Auth.currentSession())
        console.log(JSON.stringify({"token": session.getAccessToken()}, null, 4))
        const result = await API.post(apiName, path, myInit);
        return Promise.resolve(result.status)

    }


}
