import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select
} from 'antd'
import Text from "antd/es/typography/Text"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CalendarOutlined } from '@ant-design/icons'
import { DataStore } from "aws-amplify";
import { Coach, CoachedParticipant, ScheduledAppointment } from "../models"
import _ from 'lodash';
import { UserHelper } from './UserHelper';
import { Emailer } from './Emailer';
import { isValidUrl } from './urlHandler'

dayjs.extend(utc)
dayjs.extend(timezone)

export const createAppointment = async (participant: CoachedParticipant, coach: Coach, url?: string, startAt?: dayjs.Dayjs): Promise<boolean> => {
    if (participant.participant && coach.coachId && url && startAt) {
        const endAt = startAt.add(1, "hour")
        if (startAt.isValid() && endAt.isValid()) {
            try {
                const appointment = new ScheduledAppointment({ user: participant.participant, url, startAt: startAt.format("YYYY-MM-DDTHH:mm:ssZ"), endAt: endAt.toISOString(), completedAt: undefined })
                await DataStore.save(appointment)
                const userHelper = new UserHelper()


                const participantAttributes = await userHelper.getUserAttributes(participant.participant)
                const coachAttributes = await userHelper.getUserAttributes(coach.coachId)
                const emailer = new Emailer()
                const didSend = await emailer.sendAppointmentEmails(participantAttributes.email ?? "", participant.participantNickname, participantAttributes.zoneinfo ?? "", coachAttributes.email ?? "", coach.nickname, coachAttributes.zoneinfo ?? "", startAt, url)
                return Promise.resolve(didSend)
            } catch (error) {
                console.log(`ScheduledAppointment save Error: ${error}`)
                return Promise.resolve(false)
            }
        }
    }
    return Promise.resolve(false)

}

export interface CreateNewAppointmentProps {
    coachId?: string | undefined
}

export function CreateNewAppointment({ coachId }: CreateNewAppointmentProps) {
    const [createAppointmentModalVisible, setCreateAppointmentModalVisible] = useState(false)
    const [coaches, setCoaches] = useState<Coach[]>([])
    const [participant, setParticipant] = useState<CoachedParticipant | undefined>(undefined)
    const [participants, setParticipants] = useState<CoachedParticipant[]>([])


    const [url, setUrl] = useState<string | undefined>(undefined)
    const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(undefined)
    const [assignedCoachId, setAssignedCoachId] = useState<string | undefined>(undefined)
    const [currentlyCreating, setCurrentlyCreating] = useState<boolean>(false)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)

    const handleOk = () => {
        setCurrentlyCreating(true)
        const coach = coaches.find((coach) => coach.coachId === participant?.coach)
        if (participant && coach) {
            createAppointment(participant, coach, url, startDate)
                .then((didCreate) => {
                    if (didCreate) {
                        setCurrentlyCreating(false)
                        setCreateAppointmentModalVisible(false)
                        clear()
                    } else {
                        setCurrentlyCreating(false)
                    }
                })
        }
    };

    const handleCancel = () => {
        setCreateAppointmentModalVisible(false);
    };

    const showCreateNewUserModal = () => {
        setCreateAppointmentModalVisible(true)
    }

    const clear = () => {
        setAssignedCoachId(undefined)
        setParticipant(undefined)
        setUrl(undefined)
        setStartDate(undefined)

    }
    useEffect(() => {
        setCanSubmit([participant?.participant, startDate, (url && isValidUrl(url))].every((value) => Boolean(value)))
    }, [participant?.participant, url, startDate])


    useEffect(() => {
        if (coachId) {
            setAssignedCoachId(coachId)
        }
        DataStore.observeQuery(Coach)
            .subscribe((snapshot) => {
                const { items } = snapshot
                setCoaches(_.sortBy(items, "nickname"))
            })
    }, [coachId])

    useEffect(() => {
        if (assignedCoachId) {
            DataStore.observeQuery(CoachedParticipant, (participant) => participant.coach.eq(assignedCoachId))
                .subscribe((snapshot) => {
                    const { items } = snapshot
                    setParticipants(_.sortBy(items, "participantNickname"))
                })
        }

    }, [assignedCoachId])

    const onSelectDate = (date: dayjs.Dayjs | null, dateString: string) => {
        setStartDate(date ?? undefined)
    };

    return <>
        <p onClick={showCreateNewUserModal}>
            <CalendarOutlined /> Schedule a Real-Time Coaching Session
        </p>

        <Modal
            title="Schedule a Real-Time Coaching Session"
            open={createAppointmentModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    disabled={!Boolean(canSubmit)}
                    onClick={handleOk} 
                    loading={currentlyCreating}
                >
                    Schedule
                </Button>
            ]}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
            >
                <Form.Item rules={[{ required: true }]}>
                    {
                        coachId
                            ? (<Text>{coaches.find((coach) => coach.coachId === assignedCoachId)?.nickname}</Text>)
                            : (
                                <Select onChange={(value: string) => setAssignedCoachId(value)} value={assignedCoachId} placeholder="Coach">
                                    {coaches.map((coach) => {
                                        return <Select.Option value={coach.coachId} key={`option_${coach.coachId}`}>{coach.nickname}</Select.Option>
                                    })}
                                </Select>)
                    }

                </Form.Item>
                <Form.Item rules={[{ required: true }]}>
                    <Select onChange={(value: string) => setParticipant(participants.find((participant) => participant.participant === value))} value={participant?.participant ?? null} placeholder="Participant">
                        {participants.map((participant) => {
                            return <Select.Option value={participant?.participant} key={`option_${participant.participant}`} >{participant?.participantNickname}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item rules={[{ required: true }]}>
                    <DatePicker
                        onChange={onSelectDate}
                        value={startDate}
                        format="ddd M/D/YYYY h:mma"
                        showTime

                    />
                    ({dayjs.tz.guess()})
                </Form.Item>
                <Form.Item 
                >
                    <Input
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="URL"
                        value={url}
                    />
                </Form.Item>
            </Form>
        </Modal>
    </>
};

