import { withAuthenticator,WithAuthenticatorProps, Image, Button } from '@aws-amplify/ui-react';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth, DataStore, Hub, Storage } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { Schedule, TitledQuestion } from './schedule';
import { AdminView, CoachView } from './home';


import "./style.css"
import { Participant } from './participants/participant';
import { Exercise } from './exercise';
import { Groups } from './common/types';


function App({ signOut, user }: WithAuthenticatorProps) {

  const [groups, setGroups] = useState<string[]>([])
  const [questionsByActivityID, setQuestionsByActivityID] = useState<Record<string, TitledQuestion[]>>({})
  const [schedule, setSchedule] = useState<Schedule>([])
  
  const username = user?.username
  
  

  useEffect(() => {
    
    Auth.currentAuthenticatedUser().then((cognitoUser: CognitoUser) => {
      const groups = cognitoUser.getSignInUserSession()?.getAccessToken()?.payload["cognito:groups"] ?? []
      setGroups(groups)


      Promise.all([Storage.get("0.2.0/schedule.json"), Storage.get("0.2.0/questions.json")])
        .then((urls) => Promise.all(urls.map((url) => fetch(url))))
        .then((responses) => Promise.all(responses.map((response) => response.json()))) 
        .then(([schedule, questions]) => {
          setSchedule(schedule)
          setQuestionsByActivityID(questions)

          return Promise.resolve()
        }) 


    }).catch(() => {
    }) 
    
  },[username])

  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
      await DataStore.clear();
    }
  });


  return (
    <Router>
      <div className="flex-column app-frame">
        <div className="flex-row align-center header container">
          <Link to="/"><Image src={require('./resources/images/driven_logo.png')} height="48px" alt="DRIVEN" /></Link>
          <Button onClick={signOut} style={{ position: "absolute", right: 5, top: 10 }}>Sign Out</Button>
        </div>
        <div className="flex flex-row app-main">
          <div className="app-body flex">
            <Routes>
              <Route path="/" element={
                groups.includes(Groups.studyCoordinators) && user && user.username
                  ? <AdminView groups={groups} userId={user.username} /> 
                  : user?.username 
                    ? <CoachView groups={groups} userId={user?.username} />
                    : <></>
              } />
              <Route path="/participant/:participantId" element={<Participant schedule={schedule} groups={groups} />} />
              <Route path="/participant/:participantId/exercise/:activityId" element={<Exercise schedule={schedule} questionsByActivityID={questionsByActivityID} />} />
              {groups.includes(Groups.studyCoordinators) && user && user.username && <Route path="/admin" element={<AdminView groups={groups} userId={user?.username} />} /> }
              <Route path="/coach/" element={user?.username ? <CoachView groups={groups} userId={user?.username} /> : <></>} />
              <Route path="/coach/:coachId" element={user?.username ? <CoachView groups={groups} userId={user?.username} /> : <></>} />
            </Routes>
          </div>
        </div>
      </div>
      </Router>



  );
}

export default withAuthenticator(App);
