import { useEffect, useState } from "react"
import { PermissionedUserProps } from "../admin/sharedInterface"
import { DataStore } from "aws-amplify"
import { Coach, CoachedParticipant, ScheduledAppointment } from "../models"
import _ from "lodash"
import { Card, DatePicker, Popconfirm, Radio, Space, Switch } from "antd"
import dayjs from "dayjs"

import Title from "antd/es/typography/Title"
import Text from "antd/es/typography/Text"
import Link from "antd/es/typography/Link"
import { Link as RouterLink } from "react-router-dom";
import { LoadingOutlined, MailTwoTone, DeleteTwoTone  } from "@ant-design/icons"
import { UserHelper } from "../admin/UserHelper"
import { Emailer } from "../admin/Emailer"
import { Groups } from "../common/types"


export interface ScheduleViewProps extends PermissionedUserProps {
    participantId: string | undefined
    coachId: string | undefined
    title?: string | undefined
}

interface AppointmentHolder {
    title: string
    participantId?: string | undefined
    appointments: ScheduledAppointment[]
}


type DisplayedAppointmentType = 'participant' | 'date';

interface GroupBySelectorProps {
    didSelect: (value: DisplayedAppointmentType) => void
    displayedAppointmentType: DisplayedAppointmentType
    float?: 'left' | 'right' | 'inherit'
}

const GroupBySelector = ({ didSelect, displayedAppointmentType, float = "inherit" }: GroupBySelectorProps): JSX.Element => {
    return <div style={{ float: float }}>
        <Radio.Button checked={displayedAppointmentType === 'participant'} onChange={() => didSelect('participant')}>Group By Participant</Radio.Button>
        <Radio.Button checked={displayedAppointmentType === 'date'} onChange={() => didSelect('date')}>Group By Date</Radio.Button>
    </div>
}

export const saveActivityCompletionDate = async (appointment: ScheduledAppointment | undefined, activityCompletionDate: dayjs.Dayjs | undefined): Promise<boolean> => {
    if (appointment) {
        try {
            return DataStore.save(ScheduledAppointment.copyOf(appointment, updated => {
                updated.completedAt = activityCompletionDate?.format("YYYY-MM-DDTHH:mm:ssZ")
            }))
            .then(() => {
                return Promise.resolve(true)
            })
             
        } catch (error) {
            console.log(`There was an error saving activity completion date: ${error}`)
            return Promise.resolve(false)
        }
    }
    return Promise.resolve(false)
}


export const ScheduleAppointmentsView = ({ participantId, coachId, groups, title }: ScheduleViewProps): JSX.Element => {
    const [participants, setParticipants] = useState<CoachedParticipant[]>([])
    const [participantsByParticipantID, setParticipantsByParticipantID] = useState<Record<string, CoachedParticipant>>({})
    const [displayedAppointmentType, setDisplayedAppointmentType] = useState<DisplayedAppointmentType>("participant")
    const [allAppointments, setAllAppointments] = useState<Record<string, AppointmentHolder[]>>({})
    const [activityCompletionDate, setActivityCompletionDate] = useState<dayjs.Dayjs | undefined>(undefined)
    const [appointmentsById, setAppointmentsById] = useState<Record<string, ScheduledAppointment>>({})
    const [selectedDatePickerId, setSelectedDatePickerId] = useState<string | undefined>(undefined)
    const [savingByAppointmentID, setSavingByAppointmentID] = useState<Record<string, boolean>>({})
    const [anySwitchValue, setAnySwitchValue] = useState(false)
    const [coach, setCoach] = useState<Coach|undefined>(undefined)

    useEffect(() => {
        if (participantId) {
            DataStore.query(CoachedParticipant, (participant) => participant.participant.eq(participantId))
                .then((participants) => {
                    setParticipants(participants)
                })
        } else if (coachId) {
            const subscription = DataStore.observeQuery(CoachedParticipant, (participant) => participant.coach.eq(coachId))
                .subscribe((snapshot) => {
                    const { items } = snapshot
                    setParticipants(_.sortBy(items, "participantNickname"))
                })
            return () => {
                subscription.unsubscribe()
            }
        } else if (groups.includes("Admins")) {
            const subscription = DataStore.observeQuery(CoachedParticipant)
                .subscribe((snapshot) => {
                    const { items } = snapshot
                    setParticipants(_.sortBy(items, "participantNickname"))
                })
            return () => {
                subscription.unsubscribe()
            }

        }
        if (coachId) {
            DataStore.query(Coach, (coach) => coach.coachId.eq(coachId))
            .then((coaches) => {
                const currentCoach = _.first(coaches)
                setCoach(currentCoach)
            })

        }  


    }, [participantId, coachId, groups])

    useEffect(() => {
        const query = participantId ?
            DataStore.observeQuery(ScheduledAppointment, (appointment) => appointment.user.eq(participantId))
            : DataStore.observeQuery(ScheduledAppointment)
        query
        .subscribe((snapshot) => {
            // Prepare two separate ordered collections of `AppointmentHolder` objects.  Each `AppointmentHolder` represents a "row" of data. The UI switches between showing these two collections so that we can toggle between appointments grouped by participant and grouped by name.   
                const { items } = snapshot

                const participantsByID = _.keyBy(participants, (participant) => participant.participant)
                setParticipantsByParticipantID(participantsByID)

                const allParticipantIds = new Set(participants.map((participant) => participant.participant))
                const appointments = items.filter((appointment) => allParticipantIds.has(appointment.user))

                const appointmentsByID = _.keyBy(appointments, (appointment) => appointment.id)
                setAppointmentsById(appointmentsByID)

                //update saving status
                const localSavingByAppointmentID = savingByAppointmentID
                appointments.forEach((appointment) => {
                    delete localSavingByAppointmentID[appointment.id]
                })
                setSavingByAppointmentID(localSavingByAppointmentID)


                const appointmentsByParticipantID = _.groupBy(appointments, (scheduledAppointment) => scheduledAppointment.user)
                _.keys(appointmentsByParticipantID).forEach((key) => appointmentsByParticipantID[key].sort((first, second) => first.startAt.localeCompare(second.startAt)))

                const participantsWithAppointments: AppointmentHolder[] = _.keys(appointmentsByParticipantID).map((key) => {
                    const participant = participantsByID[key]
                    return { title: participant?.participantNickname ?? "", participantId: key, appointments: appointmentsByParticipantID[key] }
                })

                //find all participants that are not represented in the particpantAppointments
                const participantsWithNoAppointments = Array.from(allParticipantIds).filter((participantId) => !_.has(appointmentsByParticipantID, participantId))
                    .map((participantId) => {
                        const participant = participantsByID[participantId]
                        return { title: participant?.participantNickname ?? "", participantId: participantId, appointments: [] }
                    })

                const participantAppointments = _.concat(participantsWithAppointments, participantsWithNoAppointments)

                participantAppointments.sort((first, second) => {
                    const firstParticipant = participantsByID[first.participantId ?? ""]
                    const secondParticipant = participantsByID[second.participantId ?? ""]

                    return (!_.isNil(firstParticipant) && _.isNil(secondParticipant))
                        ? -1
                        : (_.isNil(firstParticipant) && !_.isNil(secondParticipant))
                            ? 1
                            : firstParticipant?.participantNickname.toLocaleLowerCase().localeCompare(secondParticipant?.participantNickname) ?? 0

                })

                const upcomingAppointments = appointments.filter((appointment) => dayjs(appointment.startAt).isAfter(dayjs().startOf('day')))
                const appointmentsByDate = _.groupBy(upcomingAppointments, (scheduledAppointment) => dayjs(scheduledAppointment.startAt).format("YYYYMMDD"))
                _.keys(appointmentsByDate).forEach((key) => appointmentsByDate[key].sort((first, second) => {
                    const dateSort = first.startAt?.localeCompare(second.startAt)
                    return dateSort
                }))
                const dateAppointments: AppointmentHolder[] = _.keys(appointmentsByDate).map((key) => {
                    return { title: dayjs(key).format("dddd MMMM D, YYYY"), appointments: appointmentsByDate[key] }
                })

                setAllAppointments({ "date": dateAppointments, "participant": participantAppointments })


            })
    }, [participantId, participants, savingByAppointmentID])


    const gridStyle: React.CSSProperties = {
        width: '33%'
    };

    const handleSwitch = (checked: boolean, appointmentId: string) => {
        setSelectedDatePickerId(checked ? appointmentId : undefined)
        setAnySwitchValue(checked)
        if (!checked) {
            const localSavingByAppointmentID = savingByAppointmentID
            localSavingByAppointmentID[appointmentId] = true
            setSavingByAppointmentID(localSavingByAppointmentID)

            const appointment = appointmentsById[appointmentId]
            saveActivityCompletionDate(appointment, undefined)
            console.log(`Switch: ${anySwitchValue}`)
        }
    }
    

    const onSelectDate = (date: dayjs.Dayjs | undefined, appointmentId: string) => {        
        setActivityCompletionDate(date)
        if (activityCompletionDate) {
            setSelectedDatePickerId(undefined)                

            const localSavingByAppointmentID = savingByAppointmentID
            localSavingByAppointmentID[appointmentId] = true
            setSavingByAppointmentID(localSavingByAppointmentID)
            const appointment = appointmentsById[appointmentId]
            saveActivityCompletionDate(appointment, activityCompletionDate)
            
        }
    }

    const sendReminderEmail = (appointmentID: string): Promise<boolean> => {
        const appointment = appointmentsById[appointmentID]
        const participant = participantsByParticipantID[appointment.user]

        const userHelper = new UserHelper()
        return Promise.all([userHelper.getUserAttributes(participant.participant), userHelper.getUserAttributes(participant.coach), DataStore.query(Coach, (coach) => coach.coachId.eq(participant.coach)) ])
            .then(([participantAttributes, coachAttributes, coaches]) => {
                const coach = _.first(coaches)
                const emailer = new Emailer()
                return coach 
                    ? emailer.sendAppointmentEmails(participantAttributes.email ?? "", participant.participantNickname, participantAttributes.zoneinfo ?? "", coachAttributes.email ?? "", coach.nickname, coachAttributes.zoneinfo ?? "", dayjs(appointment.startAt), appointment.url)
                    : Promise.resolve(false)
        
            })        



    }  

    const deleteAppointment = (appointmentID: string): Promise<boolean> => {
        return DataStore.query(ScheduledAppointment, appointmentID)
            .then((scheduledAppointment) => {
                if (scheduledAppointment) {
                    return DataStore.delete(scheduledAppointment)
                        .then(() => {
                            return Promise.resolve(true)
                        })
                } else {
                    return Promise.resolve(false)
                }
            })


    }  

    return (
        <>
            {
                title 
                    ? <Title level={3}>{title}</Title>
                    : null
            }
            {
                coach && groups.includes("Admins")
                    ? <Title level={5}>Coach: <RouterLink to={`/coach/${coach?.coachId}`}>{coach.nickname}</RouterLink></Title>
                    : null
            }
            
            {

                allAppointments[displayedAppointmentType]
                    ?.map((appointmentHolder, index) => {
                        const appointments = appointmentHolder.appointments
                        const appointmentTitle = _.isEmpty(appointmentHolder.title) ? appointmentHolder.participantId! : appointmentHolder.title
                        const title = appointmentHolder.participantId
                            ? <RouterLink to={`/participant/${appointmentHolder.participantId}`}>{appointmentTitle}</RouterLink>
                            : <>{appointmentTitle}</>
                        return (<Card title={<>{title} {index !== 0 ? (null) : <GroupBySelector float="right" didSelect={(value) => { setDisplayedAppointmentType(value) }} displayedAppointmentType={displayedAppointmentType} />} </>}>
                            {

                                _.isEmpty(appointments)
                                    ? (<>No scheduled appointments</>)
                                    : (appointments ?? []).map((appointment) => {
                                        const formattedDate = dayjs(appointment.startAt).format("ddd MM/DD/YYYY hh:mma")
                                        const participantName = participantsByParticipantID[appointment.user]?.participantNickname ?? "(no name)"
                                        const title = appointmentHolder.participantId ? formattedDate : participantName
                                        const subTitle = appointmentHolder.participantId ? undefined : formattedDate
                                        return (                                            
                                            <Card.Grid style={gridStyle}>
                                                <Title level={4}>{title}</Title>
                                                {subTitle ? <Title level={5}>{subTitle}</Title> : <></>}
                                                <Link href={appointment.url} target="_blank">
                                                    {appointment.url}
                                                </Link>
                                                <br />
                                                <Text>Completed</Text>
                                                <br />
                                                <Switch 
                                                    onChange={(checked) => handleSwitch(checked, appointment.id)}
                                                    checked={appointment.completedAt ? true : false}
                                                />
                                                <LoadingOutlined style={{display: savingByAppointmentID[appointment.id] ? "" : "none"}}  /> 
                                                {selectedDatePickerId === appointment.id && 
                                                    <DatePicker
                                                        open={selectedDatePickerId === appointment.id}
                                                        onSelect={(date) => {
                                                                onSelectDate(dayjs(date), appointment.id)
                                                            }
                                                        }
                                                        format="ddd DD/MM/YYYY"
                                                        autoFocus={true}
                                                        onBlur={() => {
                                                            setSelectedDatePickerId(undefined)    
                                                            return undefined
                                                        }}

                                                    />
                                                
                                                }
                                                {groups.includes(Groups.studyCoordinators) &&
                                                    <div style={{ float: "right" }}>
                                                        <Space>
                                                            <Popconfirm
                                                                title="Send Email"
                                                                description="Email the details of this appointment to the coach and participant?"
                                                                onConfirm={() => {
                                                                    sendReminderEmail(appointment.id)
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <MailTwoTone />
                                                            </Popconfirm>
                                                            
                                                            <Popconfirm
                                                                title="Delete Appointment"
                                                                description="Are you sure you want to delete this appointment?"
                                                                onConfirm={() => {
                                                                    deleteAppointment(appointment.id)
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteTwoTone />
                                                            </Popconfirm>
                                                        </Space>
                                                    </div>
                                                }                   
                                            </Card.Grid>)
                                    })
                            }
                        </Card>)

                    })
            }
        </>
    )
}