import { Schedule, TitledActivity, TitledQuestion } from "../schedule"
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { DataStore } from "aws-amplify";
import { CoachedParticipant, QuestionResponse } from "../models";
import _ from "lodash";
import { Divider, Heading, Table, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import moment from "moment";



export interface ExerciseProps {
    questionsByActivityID: Record<string, TitledQuestion[]>
    schedule: Schedule
}

export const Exercise = ({questionsByActivityID, schedule}: ExerciseProps): JSX.Element => {
    let { participantId, activityId } = useParams();
    const [questionResponseByQuestionId, setQuestionResponseByQuestionId] = useState<Record<string, QuestionResponse>>({})
    const [questions, setQuestions] = useState<TitledQuestion[]>([])
    const [participant, setParticipant] = useState<CoachedParticipant|undefined>(undefined)
    const [activity, setActivity] = useState<TitledActivity|undefined>(undefined)
    const [weekNumber, setWeekNumber] = useState<number|undefined>(undefined)

    useEffect(() => {
        if (participantId && activityId) {
            const unwrappedActivityId = activityId.toUpperCase()
            const unwrappedParticipantId = participantId
            
            DataStore.observeQuery(QuestionResponse, (questionResponse)=> questionResponse.and((questionResponse) => [
                questionResponse.activityId.eq(unwrappedActivityId),
                questionResponse.owner.eq(unwrappedParticipantId)
            ]))
            .subscribe((snapshot) => {
                const {items} = snapshot
                console.log(JSON.stringify(items))
                setQuestionResponseByQuestionId(_.keyBy(items, (questionResponse) => questionResponse.questionId ))
              })
            const weekIndex = schedule.findIndex((activities) => activities.find((activity) => activity.id === activityId))
            setWeekNumber(weekIndex + 1)
            const activity = schedule.flat()
                .find((activity) => activity.id === activityId)
            setActivity(activity)    


            if (participantId) {
                DataStore.query(CoachedParticipant, (coachedParticipant)=> coachedParticipant.participant.eq(participantId!))
                    .then((participants) => {
                        const participant = _.first(participants)
                        if (participant) {
                            setParticipant(participant)
                        }
                    })
            }
    
            

            setQuestions(questionsByActivityID[activityId])
        }
    
      }, [participantId, activityId, questionsByActivityID, schedule])

      return <>
        <Heading level={3}>{participant?.participantNickname ?? "Loading..."}</Heading>
        <Heading level={4}>{weekNumber ? `Week ${weekNumber}:` : ""} {activity?.title ?? "Loading..."}</Heading>

        {
            questions.map((question) => {
                const rawResponse = questionResponseByQuestionId[question.id.toUpperCase()]?.value ?? ""
                const momentResponse = (question.input_type === "dateTime") ? moment(rawResponse) : undefined
                const dateResponse = momentResponse?.isValid() ? momentResponse.format("ddd MM/DD/YYYY hh:mma") : undefined
                const response = dateResponse ? `${dateResponse} local time` : rawResponse    
                return (
                    <>
                    <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell><Heading level={6}>{question.content}</Heading></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableRow>
                        <TableCell>{
                        // Display every line of text on it's own line    
                        response.split("\n").map((line) => {
                            return (
                                <div>{line}</div>
                            )
                        }) 
                        }</TableCell>
                    </TableRow>

                    </Table>            
                    <Divider/>

                    </>


                )
            })
        }

      </>
}