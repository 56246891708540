import {Amplify, DataStore } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Cohort } from '../models';
Amplify.configure(awsconfig);


export class CohortHelper {


    /**
     * 
     * @param cohortStartDate 
     * @returns the Cohort
     */
    public async createCohortIfNecessary(cohortStartDate: string): Promise<Cohort> {
        const cohort = await DataStore.save(new Cohort({startDate: cohortStartDate}))
        return Promise.resolve(cohort)

    }

}