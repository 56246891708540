import { useEffect, useState } from "react";
import { IdentifiedUserProps } from "../home";
import { CoachedParticipant } from "../models";
import { DataStore } from "aws-amplify";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { TeamOutlined } from "@ant-design/icons";

export function CoachViewLink({userId}: IdentifiedUserProps)  {
    const [participants, setParticipants] = useState<CoachedParticipant[]>([])
    const navigate = useNavigate();

    useEffect(() => {
        const subscription = DataStore.observeQuery(CoachedParticipant, (participant) => participant.coach.eq(userId))
        .subscribe((snapshot) => {
            const { items } = snapshot
            setParticipants(items)
        })
        return () => {
            subscription.unsubscribe()
        }

    }, [userId])

    return _.isEmpty(participants) 
        ? <></> 
        : (<p onClick={() => navigate("/coach")}>
            <TeamOutlined /> My Participants
          </p>)
    

}