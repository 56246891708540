// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Mood = {
  "AWFUL": "awful",
  "BAD": "bad",
  "OKAY": "okay",
  "GOOD": "good",
  "GREAT": "great"
};

const Motivation = {
  "LEVEL1": "level1",
  "LEVEL2": "level2",
  "LEVEL3": "level3",
  "LEVEL4": "level4",
  "LEVEL5": "level5"
};

const { CollectedAward, ActivityMeta, VideoResponse, AssessmentResponse, QuestionResponse, ScheduledAppointment, CoachedParticipant, Coach, Cohort } = initSchema(schema);

export {
  CollectedAward,
  ActivityMeta,
  VideoResponse,
  AssessmentResponse,
  QuestionResponse,
  ScheduledAppointment,
  CoachedParticipant,
  Coach,
  Cohort,
  Mood,
  Motivation
};