import dayjs from 'dayjs';
import {Amplify, Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

interface IUserAttributes {
    email: string
    zoneinfo: string
    cohort_start_date: string
}

export interface CognitoUser {
    userName: string
    attributes: IUserAttributes
}

export class UserHelper {


    /**
     * 
     * @param email 
     * @param cohortStartDate 
     * @param zoneinfo 
     * @returns the user id
     */
    public async createUser(email: string, cohortStartDate: Date, zoneinfo: string): Promise<CognitoUser> {
        const apiName = 'AdminQueries';
        const path = '/createUser';
        let myInit = {
            body: { email, startDate: dayjs(cohortStartDate).toISOString() , zoneinfo}, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        const result = await API.post(apiName, path, myInit);
        const userName = result.userName
        const attributes = await this.getUserAttributes(userName)
        return Promise.resolve({userName, attributes})

    }

    // rename this and send userEmail and userZoneInfo
    public async getUserEmail(username: string): Promise<string|undefined> {
        const attributes = await this.getUserAttributes(username)
        const email = attributes.email
        return email
    }

    public async getUserAttributes(username: string): Promise<IUserAttributes> {
        
        const apiName = 'AdminQueries';
        const path = '/getUserAttributes';
        const myInit = { 
            queryStringParameters: { username }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
         }
        const userAttributes = await API.get(apiName, path, myInit)
        return Promise.resolve(userAttributes)

    }


}