import { ActivityMeta, AssessmentResponse, CoachedParticipant } from "../models"
import { Schedule } from "../schedule"
import '@aws-amplify/ui-react/styles.css';

import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'

import { DataStore } from "aws-amplify";
import _ from "lodash";
import { Card, Switch, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { ScheduleAppointmentsView } from "../appointments/schedule";
import { PermissionedUserProps } from "../admin/sharedInterface";

export interface ParticipantProps extends PermissionedUserProps {
    schedule: Schedule
}

type ParticipantActivity = {
    type: string
    title: string
    completed: boolean
    completedOn: Dayjs|undefined
    details: JSX.Element

}

export const Participant = ({schedule, groups}: ParticipantProps): JSX.Element => {
    const { participantId } = useParams();
    const [title, setTitle] = useState<string>("...")
    const [participant, setParticipant] = useState<CoachedParticipant|undefined>(undefined)

    useEffect(() => {
        if (participantId) {
            DataStore.query(CoachedParticipant, (coachedParticipant) => coachedParticipant.participant.eq(participantId))
            .then((participants) => {
                const currentParticipant = _.first(participants)
                setParticipant(currentParticipant)
                setTitle(currentParticipant?.participantNickname ?? "(No Name)")

            })
        }

      }, [participantId])


    return (
        <>
            <ScheduleAppointmentsView participantId={participantId} title={title} coachId={participant?.coach} groups={groups} />
            <ParticipantActivities schedule={schedule} groups={groups} />
        </> 
    )
}

const ParticipantActivities = ({schedule}: ParticipantProps): JSX.Element => {

    const { participantId } = useParams();
    const [activityMetasById, setActivityMetasById] = useState<Record<string, ActivityMeta>>({})
    const [moodAssessmentResponsesByWeek, setMoodAssessmentResponsesByWeek] = useState<Record<string,AssessmentResponse>>({})
    const [participant, setParticipant] = useState<CoachedParticipant|undefined>(undefined)
    const [participantActivitiesByWeekInOrder, setParticipantActivitiesByWeekInOrder] = useState<ParticipantActivity[][]>([[]])

    useEffect(() => {
        
        if (participantId) {

            DataStore.observeQuery(AssessmentResponse, (assessmentResponse)=> assessmentResponse.owner.eq(participantId))
                .subscribe((snapshot) => {
                    const {items} = snapshot
                    const moodAssessmentResponsesByWeek = _.keyBy(items, (moodAssessmentResponse) => `${moodAssessmentResponse.week}`)
                    setMoodAssessmentResponsesByWeek(moodAssessmentResponsesByWeek)
                })
            DataStore.observeQuery(ActivityMeta, activityMeta => activityMeta.owner.eq(participantId))
                .subscribe((snapshot) => {
                    const {items } = snapshot
                    setActivityMetasById(_.keyBy(items, (activityMeta) => activityMeta.activityId.toUpperCase()))
                  })
            DataStore.query(CoachedParticipant, (coachedParticipant) => coachedParticipant.participant.eq(participantId))
                  .then((participant) => {
                    setParticipant(_.first(participant))
                  })
    
        }
    
      }, [participantId])

      useEffect(() => {
                const participantActivitiesByWeekInOrder =  schedule.map((weekOfActivities, index) =>{ 
                    const week = index + 1
                    return weekOfActivities.map((activity) => {
                        const activityMeta = activityMetasById[`${activity.id.toUpperCase()}`]
                        const details = ((): JSX.Element => {
                            if (activity.type === "moodAssessment") {
                                const response = moodAssessmentResponsesByWeek[`${week}`]
                                const mood = response?.mood 
                                const motivation = response?.motivation 
                                return <>{_.compact([mood, motivation]).join(" / ")}</>
                            } else if (activity.type === "exercise") {
                                return  <Link to={`/participant/${participantId}/exercise/${activity.id}`}>{activity.title}</Link> 
                            }

                            return <></>
                        })()
                        const isCompleted = activityMeta?.isCompleted ?? false
                        const completeionDate = isCompleted ? dayjs(activityMeta?.updatedAt) : undefined

                        return {
                            type: _.startCase(activity.type),
                            title: activity.title,
                            completed: activityMeta?.isCompleted ?? false,
                            completedOn: completeionDate, 
                            details: details                                                    
                        }
                    })
                })
                setParticipantActivitiesByWeekInOrder(participantActivitiesByWeekInOrder)
      }, [participantId, schedule, activityMetasById, moodAssessmentResponsesByWeek])



    return (
        <Card title={`All Activities and Responses for ${participant?.participantNickname ?? "participant"}`}>
            <Tabs defaultActiveKey="1">
                {
                    _.range(schedule.length).map((weekIndex) => {
                        const weekNumber = weekIndex + 1

                        const columns: ColumnsType<ParticipantActivity> = [
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                key: 'type',
                                render: (value) => value
                            },
                            {
                                title: 'Activity',
                                dataIndex: 'title',
                                key: 'title',
                                render: (value) => value
                            },
                            {
                                title: 'Completed',
                                dataIndex: 'completed',
                                key: 'completed',
                                render: (value) => <Switch disabled={true} checked={value} />
                            },
                            {
                                title: 'On',
                                dataIndex: 'completedOn',
                                key: 'completedOn',
                                render: (value) => <>{value?.format("MM/DD/YYYY") ?? ""}</>
                            },
                            {
                                title: 'Details',
                                dataIndex: 'details',
                                key: 'details',
                                render: (value) => value
                            },
                        ]
                        const dataSource = participantActivitiesByWeekInOrder[weekIndex]

                        return (<TabPane tab={`Week ${weekNumber}`} key={`${weekNumber}`}>
                            <Table columns={columns} dataSource={dataSource} pagination={false} />


                        </TabPane>)
                    })
                }
            </Tabs>


        </Card>
    )
}



